import { enums } from '@amzn/sbux-lib';
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
    AciCourseLoadTranscriptStatus,
    CatalogItem,
    getCourseContextFromLAG,
    LearningActivityGroup,
    Registration,
    RegistrationStatus,
} from '../common';
import { useLearningActivityGroup } from '../hooks';
import { useTranscriptData } from './TranscriptContext';

export interface CourseDetails {
    aciTranscriptStatus?: AciCourseLoadTranscriptStatus;
    aciRegistrationStatus?: RegistrationStatus;
    aciCompletionDate?: Date;
    digitalTraining?: {
        numberOfCompletedCourses: number;
        courseDetails: any[];
    };
    actionAreaStatus?: enums.States;
    course?: CatalogItem;
    joinSessionInfo?: { url: string; startTimeStamp: number; endTimeStamp: number };
    learningActivityGroup: LearningActivityGroup;
    registration?: Registration;
    updateRegisteredLearningActivity?: (id: string) => void;
}

export const CourseDetailContext = createContext<CourseDetails | null>(null);

export const useCourseData = () => useContext<CourseDetails | null>(CourseDetailContext);

export const CourseDetailProvider = ({ id, children }: { id: string; children: React.ReactNode }) => {
    const { getCourseData, updateRegisteredLearningActivity, loading } = useTranscriptData();
    const [getLearningActivityGroup] = useLearningActivityGroup(id!);
    const [course, setCourse] = useState<CourseDetails>();
    useEffect(() => {
        // Check if the course is found in the learner's transcript
        const courseData = getCourseData(id);
        if (courseData) {
            setCourse(courseData);
        } else if (!loading) {
            // Wait until transcript has finished loading, then fetch the LAG details from LADS
            getLearningActivityGroup().then((result) => {
                // TODO: Handle error scenario such as no LAG found
                if (result.data) {
                    const learningActivityGroup: LearningActivityGroup = result.data.learningActivityGroup;
                    setCourse(getCourseContextFromLAG(learningActivityGroup));
                }
            });
        }
    }, [getCourseData]);
    const value = course ? { ...course, updateRegisteredLearningActivity } : null;

    return <CourseDetailContext.Provider value={value}>{children}</CourseDetailContext.Provider>;
};
