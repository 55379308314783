import React from 'react';
import { useEffect, useRef } from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { formatDate, formatTime, visibleHours, isSameDay, currentTimeInTimezone, maxTimelineHour, minTimelineHour } from './calendarUtils';
import { Event } from './Event';
import styled from 'styled-components';

export const CurrentTimeIndicator = styled.div<{ topValue: number }>`
    position: absolute;
    height: 4px;
    background-color: ${awsui.colorBackgroundSegmentActive};
    z-index: 2;
    left: 1px;
    right: 0;
    top: ${(props) => props.topValue || 0}px;
`;

export const CalendarGrid = ({ events, visibleDays, userTimezone }: { events: any[][]; visibleDays: Date[]; userTimezone: string }) => {
    const scrollToHereRef = useRef<null | HTMLDivElement>(null);
    const currentTime = currentTimeInTimezone(userTimezone);

    useEffect(() => {
        if (scrollToHereRef.current) {
            scrollToHereRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
        }
    });

    return (
        <table className='table'>
            <thead style={{ display: 'block' }}>
                <tr>
                    <th style={{ width: '40px' }}></th>
                    {visibleDays.map((day, index) => (
                        <th key={index}>{formatDate(day)}</th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ height: '400px', overflowY: 'scroll', display: 'block' }}>
                <tr style={{ height: '10px' }}></tr>
                {events.map((hour, hourIndex) => {
                    return (
                        <tr className='row' key={`row-${hourIndex}`}>
                            <th>{formatTime(visibleHours[hourIndex])}</th>
                            {visibleHours[hourIndex] === currentTime.hours() && <div ref={scrollToHereRef} />}
                            {hour.map((event, eventIndex) => {
                                // Timeline is always overlaid with the first element of the calendar column, because it is the only
                                // element that is never skipped.
                                const shouldDisplayCurrentTimeLine =
                                    hourIndex === 0 &&
                                    minTimelineHour <= currentTime.hour() &&
                                    maxTimelineHour >= currentTime.hour() &&
                                    isSameDay(visibleDays[eventIndex], currentTime.toDate()); // Only display the line for the current day column
                                const distanceFromTopOfCalendar =
                                    (currentTime.hour() - visibleHours[hourIndex]) * 60 + currentTime.minutes(); // Calendar grid is 60px height each row, so number of hours + distance from top of each hour marker is exactly the current minute count
                                if (event.skip) return null;
                                if (event.empty) {
                                    return (
                                        <td className='cell' key={`cell-${hourIndex}-${eventIndex}`}>
                                            {shouldDisplayCurrentTimeLine && (
                                                <CurrentTimeIndicator
                                                    topValue={distanceFromTopOfCalendar}
                                                    key='currentTimeLine'
                                                    data-testid='currentTimeLine'
                                                />
                                            )}
                                        </td>
                                    );
                                }
                                return (
                                    <td
                                        className='cell'
                                        rowSpan={Math.ceil(event.durationInMinutes / 60)}
                                        key={`cell-${hourIndex}-${eventIndex}`}
                                    >
                                        {shouldDisplayCurrentTimeLine && (
                                            <CurrentTimeIndicator
                                                topValue={distanceFromTopOfCalendar}
                                                key='currentTimeLine'
                                                data-testid='currentTimeLine'
                                            />
                                        )}
                                        <Event event={event} />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
