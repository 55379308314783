import {ColumnLayout, Container, Header, SpaceBetween} from '@amzn/awsui-components-react';
import React from 'react';
import styled from 'styled-components';
import {ContentCard} from '@amzn/sbux-lib';

const CustomContainer = styled(Container)`
    border: none !important;
`;
export const ContinueJourney = () => {
    return (
        <CustomContainer>
            <SpaceBetween size='l' data-testid='continue-your-journey'>
                <Header variant='h2'>Continue Your Journey</Header>
                <ColumnLayout columns={3}>
                    <ContentCard
                        title='Connect to employers'
                        description='Explore, search for, and apply to jobs through the AWS Educate Job Board.'
                        link={{
                            href: 'https://aws.amazon.com/education/awseducate/',
                            text: 'Go to Job Board',
                            ariaLabel: 'Link to go to Job Board'
                        }}
                    />
                    <ContentCard
                        title='Keep in touch'
                        description='Join the alumni slack channel to stay connected with your peers and instructors.'
                        link={{
                            href: 'https://awsdevelopers.slack.com',
                            text: 'Join Slack channel',
                            ariaLabel: 'Link to join slack channel'
                        }}
                    />
                    <ContentCard
                        title="What's next?"
                        description='Continue your learning with free or subscription training in AWS skill Builder.'
                        link={{
                            href: 'https://skillbuilder.training.aws.dev/learn',
                            text: 'Go to Skill Builder',
                            ariaLabel: 'Link to go to skill builder'
                        }}
                    />
                </ColumnLayout>
            </SpaceBetween>
        </CustomContainer>
    );
};
