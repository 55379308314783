import AppStrings from '../../resources/strings';
import {Button, Container, Header, SpaceBetween} from '@amzn/awsui-components-react';
import {CREDLY_URL} from '../../common';
import React from 'react';
import styled from 'styled-components';


const StyledGraduationContainer = styled.div`
    position: absolute;
    top: 30%;
    text-align: start;
`;
const imageSource = '/public/cloud_app_badge_credly.png';

const CustomContainer = styled(Container)`
    border: none !important;
`;
export const Graduated = () => {
    const strings = AppStrings.dashboardPage.graduated;

    return (
        <CustomContainer data-testid='graduated'>
            <SpaceBetween size='l' direction='horizontal'>
                <div>
                    <img {...(strings?.imageAltText && {alt: strings.imageAltText})} src={imageSource} width='350' />
                </div>
                <StyledGraduationContainer>
                    <SpaceBetween size='l' direction='vertical'>
                        <div>
                            <Header variant='h2'>{strings.headerTitle}</Header>
                        </div>
                        <div>
                            <p>{strings.description}</p>
                        </div>
                        <div>
                            {strings.buttonLabel && (
                                <div>
                                    <Button onClick={() => window.open(CREDLY_URL, '_blank')}>
                                        {strings.buttonLabel}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </SpaceBetween>
                </StyledGraduationContainer>
            </SpaceBetween>
        </CustomContainer>
    );
};
