import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { AppLayout, Button, Grid } from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { ActionArea, CourseHeaderV2, enums, Skeleton } from '@amzn/sbux-lib';
import { useRouter } from '@amzn/xb-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    convertDigitalTranscriptStatus,
    COURSE_DETAIL_ROUTE,
    FEATURE_FLAG,
    generatePageHeaderMetaData,
    generateStringArgs,
    getParamValues,
    getString,
    HOME_ROUTE,
} from '../../common';
import { Tabs } from '../../components';
import {
    useAccessByUserIdAndCatalogItemId,
    useCatalogItemByVersionedId,
    useNotifications,
    useRegisterVersionedCatalogItem,
} from '../../hooks';
import AppStrings from '../../resources/strings';

export const TrainingModule = () => {
    const { id: catalogItemVersionedId } = useParams<{ id: string }>();
    const { useSearchParams } = useRouter();
    const searchParams = useSearchParams();
    const lagId = getParamValues(searchParams, 'lagId');

    const catalogItemId = catalogItemVersionedId?.split(':')[0];
    const { showErrorMessage, showSuccessMessage } = useNotifications();
    const { push } = useRouter();
    const { catalogPage: pageStrings } = AppStrings;

    const { data: access } = useAccessByUserIdAndCatalogItemId(catalogItemId!);
    if (
        FEATURE_FLAG.CATALOG_ITEM_ACCESS_CONTROL &&
        access &&
        access.accessByUserIdAndCatalogItemId &&
        access.accessByUserIdAndCatalogItemId.accessType !== 'AUTHORIZED'
    ) {
        showErrorMessage('You are not authorized to access this course.', 'authorization_error');
        push(HOME_ROUTE);
    }

    const { data, loading } = useCatalogItemByVersionedId(catalogItemVersionedId!);
    const course = data?.catalogItemByVersionedId;
    const transcript = data?.transcriptByUserIdAndCatalogItemVersionedId;
    const [transcriptStatus, setTranscriptStatus] = useState(enums.States.NOT_ENROLLED);
    useEffect(
        () =>
            setTranscriptStatus(convertDigitalTranscriptStatus(data?.transcriptByUserIdAndCatalogItemVersionedId?.digitalTranscriptStatus)),
        [data],
    );
    // @ts-ignore
    const actionAreaStrings = pageStrings.headerActionArea[transcriptStatus];

    const [register, { loading: registering }] = useRegisterVersionedCatalogItem(catalogItemVersionedId!);
    const registerClickHandler = () =>
        register().then((result) => {
            if (!result.errors) {
                setTranscriptStatus(enums.States.ENROLLED);
                showSuccessMessage(`Successfully registered for "${course?.name}"`, `${catalogItemVersionedId}_registeration`);
            }
        });

    const content = () => {
        return (
            <div style={{ backgroundColor: awsui.colorBackgroundContainerContent }}>
                {loading ? (
                    <Skeleton isLoading height={200} type='image' width='100%' borderRadius={20} />
                ) : (
                    <div
                        data-testid='course-page-header'
                        style={{
                            display: 'flex',
                            padding: '28px 0',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ maxWidth: '1152px', width: '100%' }}>
                            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                                <div>
                                    <CourseHeaderV2
                                        courseTitle={course?.name}
                                        selectedLanguage={course?.languageCode}
                                        metadataItems={generatePageHeaderMetaData(course?.duration.value, course?.languageCode)}
                                    />
                                    {lagId && (
                                        <Button iconName='arrow-left' href={`${COURSE_DETAIL_ROUTE}/${lagId}?activeTab=trainings`}>
                                            {pageStrings.backToCourseOverview}
                                        </Button>
                                    )}
                                </div>
                                <ActionArea
                                    state={transcriptStatus}
                                    heading={actionAreaStrings.headerTitle}
                                    description={getString(
                                        actionAreaStrings.description,
                                        generateStringArgs(
                                            transcriptStatus,
                                            {
                                                courseDetails: transcript?.moduleDetails,
                                                numberOfCompletedCourses: transcript?.numberOfCompletedCourses,
                                            },
                                            transcript?.completionDate,
                                        ),
                                    )}
                                    {...(actionAreaStrings.actionLabel && {
                                        primaryButton: {
                                            ariaLabel: actionAreaStrings.actionLabel,
                                            buttonLabel: actionAreaStrings.actionLabel,
                                            onClick: registerClickHandler,
                                            loading: registering,
                                        },
                                    })}
                                />
                            </Grid>
                        </div>
                    </div>
                )}

                <Tabs
                    tabs={[
                        {
                            id: 'details',
                            label: pageStrings.courseDetailsTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='DetailsTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                        {
                            id: 'outline',
                            label: pageStrings.courseOutlineTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='OutlineTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        );
    };

    return <AppLayout content={content()} toolsHide navigationHide />;
};
