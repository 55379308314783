import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { Button, Grid } from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { ActionArea, CourseHeaderV2, enums, Skeleton } from '@amzn/sbux-lib';
import { useRouter } from '@amzn/xb-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    convertDigitalTranscriptStatus,
    COURSE_DETAIL_ROUTE,
    FEATURE_FLAG,
    generatePageHeaderMetaData,
    generateStringArgs,
    getParamValues,
    getString,
    HOME_ROUTE,
    DIGITAL_COURSE_NOT_ENROLLED,
    DigitalTranscriptStatus,
    INCOMPLETE_PREVIOUS_MODULES,
    getApplicationUrl,
} from '../../common';
import { Tabs } from '../../components';
import {
    useAccessByUserIdAndCatalogItemId,
    useCatalogItemByVersionedId,
    useNotifications,
    useRegisterVersionedCatalogItem,
} from '../../hooks';
import AppStrings from '../../resources/strings';
import { useTranscriptData } from '../../context/TranscriptContext';
import { checkCompletedAllPreviousCourses, isRegisteredLAG } from '../../common/utils/transcriptUtils';
import { getModuleUrl } from '../../common/utils/getModuleUrl';
import {
    CatalogItemAndChildrenTranscriptsProviderByItemId,
    useCatalogItemAndChildrenTranscripts,
} from '../../context/CatalogItemAndChildrenTranscriptsContext';

const IncompleteModulesActionArea = ({ actionAreaStrings, lagId }: { actionAreaStrings: Record<string, string>; lagId: string }) => (
    <ActionArea
        state={enums.States.ENROLLED}
        hideIllustration={true}
        heading={actionAreaStrings.headerTitle}
        {...(actionAreaStrings.actionLabel && {
            primaryButton: {
                ariaLabel: actionAreaStrings.actionLabel,
                buttonLabel: actionAreaStrings.actionLabel,
                href: `${getApplicationUrl()}${COURSE_DETAIL_ROUTE}/${lagId}?activeTab=outline`,
                target: '_self',
                variant: 'normal',
            },
        })}
    />
);

const TrainingModulePageContent = ({ lagId }: { lagId: string }) => {
    const { id: catalogItemVersionedId } = useParams<{ id: string }>();
    const { transcripts, loading: transcriptsLoading } = useTranscriptData();

    /** Check if the user has registered to the LAG associated with this digital course */
    const isCourseRegistered = isRegisteredLAG(transcripts, lagId);

    const catalogItemId = catalogItemVersionedId?.split(':')[0];
    const { showErrorMessage } = useNotifications();
    const { push } = useRouter();
    const { catalogPage: pageStrings } = AppStrings;

    const { data: access } = useAccessByUserIdAndCatalogItemId(catalogItemId!);
    if (
        FEATURE_FLAG.CATALOG_ITEM_ACCESS_CONTROL &&
        access &&
        access.accessByUserIdAndCatalogItemId &&
        access.accessByUserIdAndCatalogItemId.accessType !== 'AUTHORIZED'
    ) {
        showErrorMessage('You are not authorized to access this course.', 'authorization_error');
        push(HOME_ROUTE);
    }

    const { data, loading } = useCatalogItemByVersionedId(catalogItemVersionedId!);
    const {
        item: aciCourseItem,
        childTranscripts: aciCourseChildTranscripts,
        loading: childTranscriptsLoading,
    } = useCatalogItemAndChildrenTranscripts();
    const arePreviousCoursesCompleted = checkCompletedAllPreviousCourses(
        aciCourseItem?.children?.nodes,
        catalogItemId,
        aciCourseChildTranscripts,
    );
    const course = data?.catalogItemByVersionedId;
    const transcript = data?.transcriptByUserIdAndCatalogItemVersionedId;
    /** default state of the transcript status is set to `NOT_ENROLLED`  */
    const [transcriptStatus, setTranscriptStatus] = useState<
        enums.States | typeof DIGITAL_COURSE_NOT_ENROLLED | typeof INCOMPLETE_PREVIOUS_MODULES
    >(enums.States.NOT_ENROLLED);
    const [redirectionUrlState, setRedirectionUrlState] = useState<string>('');

    useEffect(() => {
        if (redirectionUrlState) {
            window.open(redirectionUrlState, '_blank');
        }
    }, [redirectionUrlState]);

    useEffect(() => {
        const digitalTranscript = convertDigitalTranscriptStatus(
            data?.transcriptByUserIdAndCatalogItemVersionedId?.digitalTranscriptStatus,
        );

        /** update the state and set it custom `DIGITAL_COURSE_NOT_ENROLLED` if the LAG associated with the digital course is registered  */
        if (!arePreviousCoursesCompleted) {
            setTranscriptStatus(INCOMPLETE_PREVIOUS_MODULES);
        } else if (isCourseRegistered && digitalTranscript === enums.States.NOT_ENROLLED) {
            setTranscriptStatus(DIGITAL_COURSE_NOT_ENROLLED);
        } else if (digitalTranscript !== enums.States.NOT_ENROLLED && isCourseRegistered) {
            /** update the transcript status if the transcript status is other than NOT_ENROLLED and LAG is registered  */
            setTranscriptStatus(digitalTranscript);
        }
    }, [isCourseRegistered, data, arePreviousCoursesCompleted]);
    // @ts-ignore
    const actionAreaStrings = pageStrings.headerActionArea[transcriptStatus];

    // Get the URL for the first incomplete module if NOT_STARTED or IN_PROGRESS or default to the first module if course is COMPLETED
    const actionAreaDisplayedModule: string | undefined =
        transcript?.moduleDetails?.find((m: { status: DigitalTranscriptStatus }) => m?.status !== DigitalTranscriptStatus.Completed)
            ?.moduleLevelCatalogItemID || transcript?.moduleDetails?.[0]?.moduleLevelCatalogItemID;

    const actionAreaDisplayedModuleName = course?.children?.nodes?.find(
        (m: { id: string | undefined }) => m.id === actionAreaDisplayedModule,
    )?.name;

    const [register, { loading: registering }] = useRegisterVersionedCatalogItem(catalogItemVersionedId!);
    const registerClickHandler = () => {
        if (transcriptStatus === enums.States.NOT_ENROLLED || transcriptStatus === DIGITAL_COURSE_NOT_ENROLLED) {
            /** Call register mutation only if the user is not enrolled */
            register().then((result) => {
                if (!result.errors) {
                    const trainingModuleUrl = getModuleUrl(
                        course,
                        actionAreaDisplayedModule,
                        result?.data?.registerVersionedCatalogItem?.id,
                    );
                    setRedirectionUrlState(trainingModuleUrl);
                    setTranscriptStatus(enums.States.ENROLLED);
                }
            });
        } else {
            /** Else redirect */
            const trainingModuleUrl = redirectionUrlState ?? getModuleUrl(course, actionAreaDisplayedModule, transcript?.registration?.id);
            window.open(trainingModuleUrl, '_blank');
        }
    };

    const content = () => {
        return (
            <div style={{ backgroundColor: awsui.colorBackgroundContainerContent }}>
                {loading ? (
                    <Skeleton isLoading height={200} type='image' width='100%' borderRadius={20} />
                ) : (
                    <div
                        data-testid='course-page-header'
                        style={{
                            display: 'flex',
                            padding: '28px 0',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ maxWidth: '1152px', width: '100%' }}>
                            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                                <div>
                                    <CourseHeaderV2
                                        courseTitle={course?.name}
                                        selectedLanguage={course?.languageCode}
                                        metadataItems={generatePageHeaderMetaData(
                                            course?.type?.valueDisplayString,
                                            course?.duration.value,
                                            course?.languageCode,
                                        )}
                                    />
                                    {lagId && (
                                        <Button iconName='arrow-left' href={`${COURSE_DETAIL_ROUTE}/${lagId}?activeTab=trainings`}>
                                            {pageStrings.backToCourseOverview}
                                        </Button>
                                    )}
                                </div>
                                {transcriptsLoading || childTranscriptsLoading ? (
                                    <Skeleton height={162} isLoading type='image' width='100%' borderRadius={20} />
                                ) : transcriptStatus === INCOMPLETE_PREVIOUS_MODULES ? (
                                    <IncompleteModulesActionArea actionAreaStrings={actionAreaStrings} lagId={lagId} />
                                ) : (
                                    <ActionArea
                                        state={transcriptStatus === DIGITAL_COURSE_NOT_ENROLLED ? enums.States.ENROLLED : transcriptStatus}
                                        heading={actionAreaStrings.headerTitle}
                                        description={getString(
                                            actionAreaStrings.description,
                                            generateStringArgs(
                                                transcriptStatus,
                                                {
                                                    courseTitle: course?.name,
                                                    courseDetails: transcript?.moduleDetails,
                                                    nextModuleName: actionAreaDisplayedModuleName,
                                                    numberOfCompletedCourses: transcript?.numberOfCompletedCourses,
                                                },
                                                transcript?.completionDate,
                                            ),
                                        )}
                                        {...(actionAreaStrings.actionLabel && {
                                            primaryButton: {
                                                ariaLabel: actionAreaStrings.actionLabel,
                                                buttonLabel: actionAreaStrings.actionLabel,
                                                onClick: registerClickHandler,
                                                loading: registering,
                                                variant: 'normal',
                                                ...(actionAreaStrings.actionIcon && {
                                                    iconName: actionAreaStrings.actionIcon,
                                                }),
                                            },
                                        })}
                                    />
                                )}
                            </Grid>
                        </div>
                    </div>
                )}

                <Tabs
                    tabs={[
                        {
                            id: 'details',
                            label: pageStrings.courseDetailsTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='DetailsTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                        {
                            id: 'outline',
                            label: pageStrings.courseOutlineTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='OutlineTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        );
    };

    return <>{content()}</>;
};

export const TrainingModule = () => {
    const { useSearchParams } = useRouter();
    const searchParams = useSearchParams();
    const parentId = getParamValues(searchParams, 'parentId');
    const lagId = getParamValues(searchParams, 'lagId');

    return (
        <CatalogItemAndChildrenTranscriptsProviderByItemId id={parentId}>
            <TrainingModulePageContent lagId={lagId} />
        </CatalogItemAndChildrenTranscriptsProviderByItemId>
    );
};
