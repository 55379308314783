import { Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { CourseClassItem, MILLISECONDS } from '../../common';
import { LearningActivityGroup } from '../../common/types';
import { useCourseData } from '../../context/CourseDetailContext';
import AppStrings from '../../resources/strings';
import { CourseClassDetails } from '../CourseClassDetails/CourseClassDetails';
import { CourseRecordingCard } from '../CourseRecordingCard/CourseRecordingCard';
import { WeekSelector } from '../calendar/WeekSelector';
import { addDays } from '../calendar/calendarUtils';
import { EmptyClassRecordings } from './EmptyClassRecordings';

export interface RegisteredScheduleProps {
    classItem?: CourseClassItem;
    setShowClassSelection: (showClassSelection: boolean) => void;
    week: Date;
    setWeek: (week: Date) => void;
}

export const RegisteredSchedule = ({ classItem, setShowClassSelection, week, setWeek }: RegisteredScheduleProps) => {
    const { coursePage: pageStrings } = AppStrings;
    const learningActivityGroup = useCourseData()?.learningActivityGroup;
    const joinSessionUrl = useCourseData()?.joinSessionInfo?.url;
    const broadcastData = mapLearningActivityGroupToBroadcastItems(learningActivityGroup, classItem?.id);
    const currentWeekBroadcastData = broadcastData.filter((broadcast) => broadcast.date >= week && broadcast.date < addDays(week, 6));

    return (
        <SpaceBetween size='s' direction='vertical'>
            <Container data-testid='registered-schedule'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Header variant='h3'>{`${pageStrings.registeredClassesHeader}`}</Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            wrapText={false}
                            onClick={() => setShowClassSelection(true)}
                            variant='link'
                        >{`${pageStrings.changeSchedule}`}</Button>
                        {joinSessionUrl && (
                            <Button wrapText={false} variant='normal' href={joinSessionUrl}>{`${pageStrings.joinLive}`}</Button>
                        )}
                    </div>
                </div>

                {classItem && (
                    <>
                        <p style={{ padding: '8px 0px' }}>{`${pageStrings.registeredClassesSubheader}`}</p>
                        <CourseClassDetails item={classItem} />
                    </>
                )}
            </Container>

            <Header variant='h2'>{`${pageStrings.classesRecordings}`}</Header>
            <SpaceBetween size='m' direction='horizontal'>
                <WeekSelector week={week} setWeek={setWeek} />
                <Header variant='h3'>
                    {`${week.toLocaleDateString('en-us', {
                        month: 'long',
                        day: 'numeric',
                    })}–${addDays(week, 6).toLocaleDateString('en-us', {
                        month: 'long',
                        day: 'numeric',
                    })}, ${addDays(week, 6).getFullYear()}`}
                </Header>
            </SpaceBetween>

            {currentWeekBroadcastData.length > 0 ? (
                currentWeekBroadcastData.map((broadcast, index) => (
                    <CourseRecordingCard key={`broadcastCard-${index}`} index={index} item={broadcast} />
                ))
            ) : (
                <EmptyClassRecordings />
            )}
        </SpaceBetween>
    );
};

export const mapLearningActivityGroupToBroadcastItems = (lag?: LearningActivityGroup, selectedActivity?: string) => {
    if (!lag || !selectedActivity) {
        return [];
    }
    const learningActivity = lag.learning_activities?.find((activity) => activity.pk === selectedActivity);
    if (!learningActivity) {
        return [];
    }

    return learningActivity.delivery_sessions
        .filter((deliverySession) => deliverySession.v_ilt_info?.recording_url)
        .map((deliverySession) => ({
            title: deliverySession.delivery_session_type,
            date: new Date(deliverySession.start_timestamp * MILLISECONDS),
            recordingUrl: deliverySession.v_ilt_info.recording_url!,
            duration: (deliverySession.end_timestamp - deliverySession.start_timestamp) / 60,
            description: lag.catalog_item.description, // TODO: Check validity, this seems like all recording descriptions will be the same as the containing catalog item description
            watched: false, // pVILT attendance + watched data CC @ 10/31
        }))
        .sort((session1, session2) => session1.date.getTime() - session2.date.getTime());
};
