import { Environment } from '@amzn/xb-hooks';

export enum NodeEnvironment {
    TEST = 'test',
    DEVELOPMENT = 'development',
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod',
}

export const getNodeEnvironment = (environmentValue: string = process.env.NODE_ENV || ''): NodeEnvironment => {
    const environment = Object.keys(NodeEnvironment).filter((key) => key === environmentValue.toUpperCase())[0];

    if (!environment) {
        throw new Error(`Node environment [${environmentValue}] is not valid`);
    }
    return NodeEnvironment[environment as keyof typeof NodeEnvironment];
};

export const getXBEnvironment = (): Environment => {
    switch (getNodeEnvironment()) {
        case NodeEnvironment.DEVELOPMENT:
        case NodeEnvironment.TEST:
            return 'local';
        case NodeEnvironment.BETA:
            return 'beta';
        case NodeEnvironment.GAMMA:
            return 'gamma';
        case NodeEnvironment.PROD:
            return 'prod';
    }
};

export const REGISTRATION_MANAGEMENT_URL: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://dmlhdhm0oulht.cloudfront.net/registermanage',
    [NodeEnvironment.DEVELOPMENT]: 'https://dmlhdhm0oulht.cloudfront.net/registermanage',
    [NodeEnvironment.BETA]: 'https://dmlhdhm0oulht.cloudfront.net/registermanage',
    [NodeEnvironment.GAMMA]: 'https://billing.skillbuilder.training.aws.dev/registermanage',
    [NodeEnvironment.PROD]: 'https://billing.skillbuilder.aws/registermanage',
};

export const MANAGE_PAYMENT_URL: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentstatus',
    [NodeEnvironment.DEVELOPMENT]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentstatus',
    [NodeEnvironment.BETA]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentstatus',
    [NodeEnvironment.GAMMA]: 'https://billing.skillbuilder.training.aws.dev/enrollmentstatus',
    [NodeEnvironment.PROD]: 'https://billing.skillbuilder.aws/enrollmentstatus',
};

export const ENROLLMENT_SELECTION_URL: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentselection',
    [NodeEnvironment.DEVELOPMENT]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentselection',
    [NodeEnvironment.BETA]: 'https://dmlhdhm0oulht.cloudfront.net/enrollmentselection',
    [NodeEnvironment.GAMMA]: 'https://billing.skillbuilder.training.aws.dev/enrollmentselection',
    [NodeEnvironment.PROD]: 'https://billing.skillbuilder.aws/enrollmentselection',
};

export const getEnrollmentSelectionUrl = () => {
    return ENROLLMENT_SELECTION_URL[getNodeEnvironment()];
};

export const getRegistrationManagementUrl = () => {
    return REGISTRATION_MANAGEMENT_URL[getNodeEnvironment()];
};

export const getManagePaymentUrl = () => {
    return MANAGE_PAYMENT_URL[getNodeEnvironment()];
};

export const APPLICATION_URL: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://beta.awscloudinstitute.skillbuilder.training.aws.dev',
    [NodeEnvironment.DEVELOPMENT]: 'http://localhost:3000',
    [NodeEnvironment.BETA]: 'https://beta.awscloudinstitute.skillbuilder.training.aws.dev',
    [NodeEnvironment.GAMMA]: 'https://awscloudinstitute.skillbuilder.training.aws.dev',
    [NodeEnvironment.PROD]: 'https://awscloudinstitute.skillbuilder.aws',
};

export const getApplicationUrl = () => {
    return APPLICATION_URL[getNodeEnvironment()];
};

export const CURRICULUM_SERVICE_BASE_URL: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://bh1x647t0h.execute-api.us-west-2.amazonaws.com/beta/v1',
    [NodeEnvironment.DEVELOPMENT]: 'https://bh1x647t0h.execute-api.us-west-2.amazonaws.com/beta/v1',
    [NodeEnvironment.BETA]: 'https://bh1x647t0h.execute-api.us-west-2.amazonaws.com/beta/v1',
    [NodeEnvironment.GAMMA]: 'https://sk0qx79j5c.execute-api.us-west-2.amazonaws.com/gamma/v1',
    [NodeEnvironment.PROD]: 'TBD_SECURITY_APPROVAL',
};

export const getCurriculumServiceUrl = () => {
    return `${CURRICULUM_SERVICE_BASE_URL[getNodeEnvironment()]}/homecomingOptions`;
};

export const SKILLBUILDER_LEARNER_DASHBOARD_URLs: Record<string, string> = {
    [NodeEnvironment.TEST]: 'https://beta.skillbuilder.training.aws.dev/learn',
    [NodeEnvironment.DEVELOPMENT]: 'https://beta.skillbuilder.training.aws.dev/learn',
    [NodeEnvironment.BETA]: 'https://beta.skillbuilder.training.aws.dev/learn',
    [NodeEnvironment.GAMMA]: 'https://skillbuilder.training.aws.dev/learn',
    [NodeEnvironment.PROD]: 'https://skillbuilder.aws/learn',
};

export const getSkillBuilderLearnerDashboard = () => {
    return SKILLBUILDER_LEARNER_DASHBOARD_URLs[getNodeEnvironment()];
};
