import React from 'react';
import { Button, Checkbox, Container, Header, SegmentedControl, SpaceBetween } from '@amzn/awsui-components-react';
import { DeviceWidth, EventSchedule } from '../../common';
import './Calendar.scss';
import {
    addDays,
    exportCalendar,
    filterEventsForDay,
    createCalendarGrid,
    getPreviousMonday,
    getVisibleDays,
    useWindowWidth,
    isEmptyWeek,
} from './calendarUtils';
import { Skeleton } from '@amzn/sbux-lib';
import AppStrings from '../../resources/strings';
import { CalendarGrid } from './CalendarGrid';
import { CalendarList } from './CalendarList';
import { WeekSelector } from './WeekSelector';
import { useNotifications, useUserInfo } from '../../hooks';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { CourseType, NotRegistered } from './NotRegistered';

export interface CalendarProps {
    schedule: EventSchedule;
}

export const Calendar = ({ schedule }: CalendarProps) => {
    // Find all unique courses which are part of the schedule
    const uniqueCourses = Array.from(new Set(schedule.events.map((event) => event.catalogItemName))).sort();

    const isEnabled = (catalogItemName: string) => {
        const index = uniqueCourses.indexOf(catalogItemName);
        return isCourseEnabled[index];
    };
    const getColor = (catalogItemName: string) => {
        const colors = ['blueStripe', 'greenStripe', 'orangeStripe', 'redStripe'];
        const index = uniqueCourses.indexOf(catalogItemName);
        return colors[index];
    };

    schedule.events.forEach((event) => (event.color = getColor(event.catalogItemName)));

    const {
        dashboardPage: { calendar },
    } = AppStrings;
    const width = useWindowWidth();
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    const [week, setWeek] = React.useState(getPreviousMonday(new Date()));
    const [view, setView] = React.useState('calendar');
    // By default all courses are enabled
    const [isCourseEnabled, setIsCourseEnabled] = React.useState(uniqueCourses.map((course) => true));
    const visibleDays = getVisibleDays(week);
    const weekEmpty = isEmptyWeek(week, schedule);

    return (
        <Container data-testid='calendar'>
            <SpaceBetween size='l'>
                <SpaceBetween size='l' direction='horizontal'>
                    <WeekSelector week={week} setWeek={setWeek} />
                    <Header variant='h3'>
                        {`${week.toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                        })} - ${addDays(week, 4).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        })}`}
                    </Header>
                    <SpaceBetween size='s' direction='horizontal' className='floatRight'>
                        <SegmentedControl
                            selectedId={view}
                            onChange={({ detail }) => setView(detail.selectedId)}
                            options={[
                                { text: calendar.calendar, id: 'calendar' },
                                { text: calendar.list, id: 'list' },
                            ]}
                        />
                        <Button
                            iconAlign='right'
                            ariaLabel='download calendar'
                            iconName='download'
                            disabled={weekEmpty}
                            onClick={() =>
                                exportCalendar(schedule)
                                    .then((error) => showErrorMessage(error?.cause as string, 'export_error'))
                                    .catch((error) => showErrorMessage(error?.message, 'export_error'))
                            }
                        >
                            {calendar.exportCalendar}
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
                {weekEmpty ? (
                    <NotRegistered courseType={CourseType.LEARNING_ACTIVITY} />
                ) : (
                    <div className='calendar-container'>
                        <div style={{ flexBasis: '85%' }}>
                            {view === 'calendar' && width > DeviceWidth.Laptop ? (
                                <CalendarGrid
                                    events={createCalendarGrid(visibleDays, schedule, isEnabled)}
                                    visibleDays={visibleDays}
                                    userTimezone={user.timezone!}
                                />
                            ) : (
                                <CalendarList filterEvents={filterEventsForDay(schedule, isEnabled)} visibleDays={visibleDays} />
                            )}
                        </div>
                        <div style={{ flex: 1 }} data-testid='view-options'>
                            <SpaceBetween size='m' className='checkboxes'>
                                <b>View</b>
                                {uniqueCourses.map((course, index) => (
                                    <Checkbox
                                        key={course}
                                        onChange={({ detail }) => {
                                            const isCourseEnabledCopy = [...isCourseEnabled];
                                            isCourseEnabledCopy[index] = detail.checked;
                                            setIsCourseEnabled(isCourseEnabledCopy);
                                        }}
                                        checked={isCourseEnabled[index]}
                                    >
                                        {course}
                                    </Checkbox>
                                ))}
                            </SpaceBetween>
                            <span
                                style={
                                    width > DeviceWidth.Tablet
                                        ? { position: 'absolute', bottom: awsui.spaceScaledM }
                                        : { display: 'block', marginTop: awsui.spaceScaledM }
                                }
                            >
                                {calendar.timezone}
                                {user.timezone}
                            </span>
                        </div>
                    </div>
                )}
            </SpaceBetween>
        </Container>
    );
};
