import moment from 'moment';
import { DEFAULT_TIMEZONE } from '../constants';

export const convertSecondsToHoursMinutes = (totalSeconds: number) => {
    const hours = Math.floor(moment.duration(totalSeconds, 'seconds').asHours());
    const minutes = Math.floor(moment.duration(totalSeconds, 'seconds').minutes());
    if (hours === 0) {
        return `${minutes} minutes`;
    } else if (minutes === 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
        return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minutes`;
    }
};

export const getTimezoneOrDefault = (timezone?: string) => {
    // Valid timezone IANA strings are in the format "COUNTRY/CITY_OR_STATE", or the default value of "UTC".
    // IANA strings will be used as-is. Anything otherwise defaults to Pacific time.
    return timezone && timezone.includes('/') ? timezone : DEFAULT_TIMEZONE;
};
