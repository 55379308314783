export const getModuleUrl = (catalogItem: any, moduleVariantId: string | undefined, registrationId: string | undefined | null) => {
    const moduleId = moduleVariantId ?? catalogItem?.children?.nodes?.[0]?.id;

    if (!moduleId) {
        throw new Error(`moduleVariantId is not defined for ${catalogItem?.id}`);
    }

    const trainingDeliveryUrl = catalogItem?.children?.nodes?.find(
        (m: { id: string | undefined }) => m.id === moduleId,
    )?.trainingDeliveryUrl;

    if (!trainingDeliveryUrl) {
        throw new Error(`trainingDeliveryUrl is not defined for ${catalogItem?.id}`);
    }

    if (!registrationId) {
        throw new Error(`Registration id is not defined for ${catalogItem?.versionedId}`);
    }

    const appendQueryParam = (baseUrl: string | URL, paramName: string, paramValue: string) => {
        const url = new URL(baseUrl);
        url.searchParams.append(paramName, paramValue);
        return url.toString();
    };

    const _url = appendQueryParam(trainingDeliveryUrl, 'product_id', catalogItem?.versionedId);

    const url = appendQueryParam(_url, 'registration_id', registrationId);
    const finalUrl = appendQueryParam(url, 'navigation', 'aci');

    return finalUrl;
};
