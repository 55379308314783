import { enums } from '@amzn/sbux-lib';
import { DIGITAL_COURSE_NOT_ENROLLED, INCOMPLETE_PREVIOUS_MODULES } from '../common';

export default {
    coursePage: {
        courseDetailsTabHeading: 'Details',
        courseClassesTabHeading: 'Classes',
        courseTrainingTabHeading: 'Outline',
        headerActionArea: {
            [enums.States.NOT_REGISTERED]: {
                headerTitle: 'Register for the next quarter',
                description: 'Each quarter, select your AWS Cloud Institute course load to continue your journey at your own pace.',
                actionLabel: 'Register',
            },
            // TODO: Update Strings
            [enums.States.IN_PROGRESS]: {
                headerTitle: 'Keep going to reach your goal',
                description: 'Trainings completed: {completedTrainings} out of {totalTrainings}',
                actionLabel: '',
            },
            [enums.States.ENROLLED]: {
                headerTitle: "You're ready for the next quarter",
                description: 'Check back on the first day of the quarter to attend class and get access to self-paced training',
                actionLabel: '',
            },
            [enums.States.COMPLETE]: {
                headerTitle: 'Congratulations!',
                description: 'You completed this course on {completionDate}',
                actionLabel: 'Explore catalog', // TODO - Need to confirm this
            },
            [enums.States.FEEDBACK]: {
                headerTitle: '',
                description: '',
                actionLabel: '3',
            },
            [enums.States.RETIRED]: {
                headerTitle: 'This course is no longer available',
                description: 'Explore the catalog for our latest offerings',
                actionLabel: 'Explore catalog', // TODO - Need to confirm this
            },
            /** Using this to be the state when the course is registered, but there are no classes selected */
            [enums.States.NOT_ENROLLED]: {
                headerTitle: 'Choose class schedule',
                description:
                    'You have multiple class schedule options to choose from. When you choose a schedule, your dashboard calendar will show your classes.',
                actionLabel: '',
            },
            [enums.States.RECTANGLE]: {
                headerTitle: '',
                description: '',
                actionLabel: '',
            },
            [enums.States.TROPHY]: {
                headerTitle: 'Congratulations!',
                description: 'You completed this course on {completionDate}.',
                actionLabel: '', // TODO - Need to confirm this
            },
        },
        learningPlansTrainingHeader: 'Trainings',
        learningPlanCourseType: 'AWS Cloud Institute digital course',
        enrolledButtonLabel: 'Go to training',
        notEnrolledButtonLabel: 'Preview training',
        officeHoursHeadingText: 'Office hours',
        officeHoursDescription: "Attend office hours as often as you'd like to ask questions and receive support from instructors.",
        officeHoursButtonLabel: 'Book time with an instructor',
        unregisteredClassesHeader: 'Class schedule options',
        unregisteredClassesSubheader:
            'Instructor-led training occurs live, 5 days a week, and all training is recorded. You can select a class when you register for the course, and you can change your class at any time during the quarter, if space allows.',
        registeredClassesHeader: 'Your class schedule',
        registeredClassesSubheader:
            'Instructor-led training occurs live, 5 days a week, and all training is recorded. You can change your class at any time during the quarter, if space allows.',
        classesOption: 'Option',
        classesInstructors: 'Instructors',
        classesSchedule: 'Schedule',
        classesRecordings: 'Class recordings',
        changeSchedule: 'Change schedule',
        joinLive: 'Join live',
        classesToday: 'Today',
        cancelSave: 'Cancel',
        confirmSave: 'Save',
        saveSuccess: 'You have successfully changed your class schedule',
        saveFirstSelectionSuccess: 'You have successfully selected a class',
    },
    dashboardPage: {
        courseNotRegistered: {
            headerTitle: 'No registered courses yet',
            description: 'Register for AWS Cloud Institute for the upcoming quarter.',
            buttonLabel: 'Register',
            imageAltText: 'No registered courses yet',
        },
        sessionNotRegistered: {
            headerTitle: 'No schedule yet',
            description: 'To create your schedule, open each course and choose a class option.',
            buttonLabel: '',
            imageAltText: 'No schedule yet',
        },
        calendar: {
            liveLearning: 'Live Learning',
            programCalendar: 'Program Calendar',
            careerChats: 'Career Chats',
            exportCalendar: 'Export',
            calendar: 'Calendar',
            list: 'List',
            today: 'Today',
            timezone: 'Timezone: ',
            calendarExportError: 'Somthing went wrong. Please retry later.',
            calendarExportNoDataError: 'The calendar has no events.',
        },
        // TODO: Check if it's the user's first login (not sure if possible)
        welcomeUnknown: 'Welcome back to AWS Cloud Institute',
        welcomeBack: 'Welcome back, ',
        managePayment: 'Manage Payment',
        manageEnrollment: 'Manage enrollment',
        nextPayment: 'Next payment May 3, 2024',
        nextQuarter: 'Next quarter',
        yourCourses: 'Your courses',
    },

    catalogPage: {
        courseDetailsTabHeading: 'Details',
        courseOutlineTabHeading: 'Outline',
        backToCourseOverview: 'Back to Course Overview',
        headerActionArea: {
            [enums.States.NOT_ENROLLED]: {
                headerTitle: 'Enroll to get started with this training',
                description: '',
                actionLabel: 'Enroll',
            },
            [enums.States.ENROLLED]: {
                headerTitle: 'Start learning',
                description: '{courseName}',
                actionLabel: 'Start',
                actionIcon: 'external',
            },
            [enums.States.IN_PROGRESS]: {
                headerTitle: 'Keep going to reach your goal',
                description: 'Trainings completed: {completedTrainings} out of {totalTrainings}',
                actionLabel: '',
            },
            [enums.States.COMPLETE]: {
                headerTitle: 'Congratulations!',
                description: 'You completed this training',
                actionLabel: '',
            },
            [DIGITAL_COURSE_NOT_ENROLLED]: {
                headerTitle: 'Start learning',
                description: '{courseName}',
                actionLabel: 'Start',
                actionIcon: 'external',
            },
            [INCOMPLETE_PREVIOUS_MODULES]: {
                headerTitle: 'Complete all previous trainings in the course to start this one',
                description: '',
                actionLabel: 'Return to course',
                actionIcon: '',
            },
        },
    },
};
