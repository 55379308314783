import moment from 'moment';
import { DEFAULT_TIMEZONE } from '../constants';

export const getHoursMinutesFromSeconds = (totalSeconds: number) => {
    return {
        hours: Math.floor(moment.duration(totalSeconds, 'seconds').asHours()),
        minutes: Math.floor(moment.duration(totalSeconds, 'seconds').minutes()),
    };
};

export const convertSecondsToHoursMinutes = (totalSeconds: number) => {
    const { hours, minutes } = getHoursMinutesFromSeconds(totalSeconds);
    if (hours === 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (minutes === 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
        return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
};

export const getTimezoneOrDefault = (timezone?: string) => {
    // Valid timezone IANA strings are in the format "COUNTRY/CITY_OR_STATE", or the default value of "UTC".
    // IANA strings will be used as-is. Anything otherwise defaults to Pacific time.
    return timezone && timezone.includes('/') ? timezone : DEFAULT_TIMEZONE;
};

const getDisplayString = (totalSeconds: number) => {
    const { hours, minutes } = getHoursMinutesFromSeconds(totalSeconds);
    if (hours > 0) {
        return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
    }
    return minutes > 0 ? `${minutes}m` : '< 1m';
};

export const formattedCourseDuration = (totalSeconds: number) => {
    return {
        ariaLabel: `Duration ${convertSecondsToHoursMinutes(totalSeconds)}`,
        valueDisplayString: getDisplayString(totalSeconds),
    };
};

export const isWithinTimeWindow = (sessionStartTime: number | null, sessionEndTime: number | null): boolean => {
    if (!sessionStartTime || !sessionEndTime) {
        return false;
    }
    const presentTime = new Date().getTime();
    const fiveMinutesInMs = 5 * 60 * 1000;
    const windowStartTime = sessionStartTime - fiveMinutesInMs;

    return presentTime >= windowStartTime && presentTime <= sessionEndTime;
};
