import { gql, useQuery } from '@apollo/client';
import { useNotifications } from '../notifications/useNotifications';

export const CATALOG_ITEM_BY_ID = gql`
    query getCatalogItemById($id: ID!) {
        catalogItemById(id: $id) {
            auditMetadata {
                lastUpdatedAt
                activatedAt
                __typename
            }
            awsServices {
                keyDisplayString
                values
                valuesDisplayStrings
                __typename
            }
            badgeImageUrl {
                value
                __typename
            }
            badgeName {
                keyDisplayString
                value
                __typename
            }
            children {
                nodes {
                    auditMetadata {
                        activatedAt
                        __typename
                    }
                    status
                    trainingObjectUrl
                    trainingDeliveryUrl
                    duration {
                        unit
                        value
                        __typename
                    }
                    description
                    id
                    versionedId
                    languageCode
                    name
                    outline
                    price {
                        value
                        __typename
                    }
                    reviewInsights {
                        averageRating
                        reviewCount
                        __typename
                    }
                    rootId
                    semanticVersion {
                        major
                        minor
                        patch
                        __typename
                    }
                    type {
                        keyDisplayString
                        value
                        valueDisplayString
                        __typename
                    }
                    skillLevel {
                        keyDisplayString
                        value
                        valueDisplayString
                        __typename
                    }
                    __typename
                }
                __typename
            }
            completionBadge {
                value
                __typename
            }
            completionCertificate {
                value
                __typename
            }
            description
            domain {
                keyDisplayString
                value
                valueDisplayString
                __typename
            }
            duration {
                unit
                value
                __typename
            }
            examCertificationImageUrl {
                keyDisplayString
                value
                __typename
            }
            examCertificationName {
                keyDisplayString
                value
                __typename
            }
            expirationDate {
                keyDisplayString
                value
                __typename
            }
            goal {
                keyDisplayString
                value
                __typename
            }
            id
            intendedAudience {
                keyDisplayString
                values
                __typename
            }
            languageCode
            name
            navigationSettings {
                keyDisplayString
                value
                valueDisplayString
                __typename
            }
            objectives {
                keyDisplayString
                values
                __typename
            }
            prerequisites {
                keyDisplayString
                value
                __typename
            }
            price {
                keyDisplayString
                value
                valueDisplayString
                __typename
            }
            reviewInsights {
                averageRating
                reviewCount
                __typename
            }
            rootId
            rootItem {
                defaultVariant {
                    id
                    trainingObjectUrl
                    __typename
                }
                presentVariantInstances {
                    dimensions {
                        key
                        value
                        __typename
                    }
                    __typename
                }
                variantItems {
                    nodes {
                        id
                        languageCode
                        trainingObjectUrl
                        __typename
                    }
                    __typename
                }
                __typename
            }
            skillLevel {
                keyDisplayString
                value
                valueDisplayString
                __typename
            }
            status
            successor {
                name
                trainingObjectUrl
                __typename
            }
            type {
                keyDisplayString
                value
                valueDisplayString
                __typename
            }
            versionedId
            parents {
                nodes {
                    id
                    name
                    trainingObjectUrl
                    versionedId
                    __typename
                }
                __typename
            }
            previewVideoUrl
            trainingDeliveryUrl
            __typename
        }
    }
`;

export const useCatalogItem = (id?: string | null) => {
    console.log(id);
    const { showErrorMessage } = useNotifications();
    return useQuery(CATALOG_ITEM_BY_ID, {
        variables: {
            id,
        },
        skip: !id,
        onError: (error) => {
            showErrorMessage(error.message, `${id}_error`);
        },
    });
};
