import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useUserInfo } from '../user';

export const GET_TRANSCRIPT_ID_AND_STATUS = gql`
    query transcriptByUserIdAndCatalogItemId($userId: ID!, $catalogItemId: ID!) {
        transcriptByUserIdAndCatalogItemId(userId: $userId, catalogItemId: $catalogItemId) {
            digitalTranscriptStatus
            numberOfCompletedCourses
            registration {
                id
            }
            moduleDetails {
                status
                moduleLevelCatalogItemID
                moduleVersion
                version
            }
            completionDate
        }
    }
`;

const useGetLazyTranscriptIdAndStatus = () => {
    const { user } = useUserInfo();

    const userId = user?.id;

    const [getTranscript, { loading, error }] = useLazyQuery(GET_TRANSCRIPT_ID_AND_STATUS);

    const fetchTranscript = useCallback(
        async (variantId: string | null | undefined) => {
            if (!userId || !variantId) {
                return null;
            }

            try {
                const { data } = await getTranscript({
                    variables: {
                        userId,
                        catalogItemId: variantId,
                    },
                });
                return data?.transcriptByUserIdAndCatalogItemId || null;
            } catch (err) {
                console.error('Error fetching transcript:', err);
                return null;
            }
        },
        [userId, getTranscript],
    );

    return useMemo(
        () => ({
            fetchTranscript,
            loading,
            error,
        }),
        [fetchTranscript, loading, error],
    );
};

export default useGetLazyTranscriptIdAndStatus;
