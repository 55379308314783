import { enums, MetadataItem, ProductCard } from '@amzn/sbux-lib';
import { Locales, useRouter } from '@amzn/xb-hooks';
import React from 'react';
import {
    ENROLLMENT_IS_OPEN,
    QUARTER_DATES,
    DIGITAL_COURSE_NOT_ENROLLED,
    INCOMPLETE_PREVIOUS_MODULES,
    LATE_REGISTRATION_GRACE_DAYS,
    MILLISECONDS,
} from '../constants';
import { COURSE_DETAIL_ROUTE } from '../routes';
import { AciTranscript, CatalogItem, RegistrationStatus } from '../types';
import { convertSecondsToHoursMinutes, formattedCourseDuration } from './dateUtils';
import { ProductCardTranslations } from '@amzn/sbux-lib/dist/esm/ProductCard/interfaces/ProductCardTranslations';
import { CourseDetails } from '../../context/CourseDetailContext';

export const getWeekDay = (date: Date) => {
    const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return WEEKDAYS[date.getDay()];
};

export const productCardFromTranscript = (transcript: AciTranscript) => {
    let course: CatalogItem = transcript.learningActivityGroup.catalog_item;
    const { push } = useRouter();
    const defaultHref = `${COURSE_DETAIL_ROUTE}/${transcript.learningActivityGroup.pk}`;
    const hrefSessionEnrolled = `${defaultHref}?activeTab=outline`;
    const hrefSessionNotEnrolled = `${defaultHref}?activeTab=classes`;
    course = {
        ...course,
        trainingObjectUrl: transcript.registration.learningActivity ? hrefSessionEnrolled : hrefSessionNotEnrolled,
    };
    const formattedDuration = formattedCourseDuration(course.duration.value);
    const productCardTranslations: ProductCardTranslations = {
        numberOfTrainings: course.childrenCount + '',
        languageCode: {
            valueDisplayString: Locales.ENGLISH.displayName,
        },
        duration: {
            valueDisplayString: formattedDuration.valueDisplayString,
            ariaLabel: formattedDuration.ariaLabel,
        },
        reviewInsights: {
            none: 'None',
            ariaLabel: 'No rating yet',
        },
        badges: {
            underMaintenance: {
                valueDisplayString: 'Under Maintenance',
            },
            retiringSoon: {
                valueDisplayString: 'Retiring soon',
            },
            unlock: {
                ariaLabel: 'Subscribed',
            },
            subscription: {
                valueDisplayString: 'Subscription',
            },
            notStarted: {
                valueDisplayString: 'Not Started',
            },
            inProgress: {
                valueDisplayString: 'In Progress',
            },
            complete: {
                valueDisplayString: 'Complete',
            },
            incomplete: {
                valueDisplayString: 'Incomplete',
            },
            assigned: {
                valueDisplayString: 'Assigned',
            },
        },
        more: 'more',
        new: 'NEW',
        updated: 'UPDATED',
    };
    return (
        <ProductCard
            key={transcript.id}
            catalogItem={course}
            translations={productCardTranslations}
            transcriptStatus={transcript.aciTranscriptStatus}
            onClick={(e, href) => {
                e.preventDefault();
                push(href!);
            }}
        />
    );
};

export const isAciEnrollmentOpen = (date: Date): boolean => {
    const mostRecentDate: string | undefined = Object.keys(ENROLLMENT_IS_OPEN)
        .filter((enrollmentDate) => new Date(enrollmentDate) <= date)
        .sort()
        .pop();
    if (!mostRecentDate) {
        return false;
    }

    // Enrollment is open according to the regular schedule
    if (ENROLLMENT_IS_OPEN[mostRecentDate]) {
        return true;
    }

    // Enrollment is open according to the late schedule
    const lateRegistrationDeadline = new Date(mostRecentDate);
    lateRegistrationDeadline.setDate(lateRegistrationDeadline.getDate() + LATE_REGISTRATION_GRACE_DAYS);

    return date <= lateRegistrationDeadline;
};

export const isAciEnrollmentOpenNow = (): boolean => {
    return isAciEnrollmentOpen(new Date());
};

/**
 *
 * @param quarterDetails - array that contains the start and end date of the quarter
 * @param startDate - date that needs to be validated if it falls within a quarter
 * @param endDate (optional) - end date that needs to be validated
 * @returns {boolean} if the date falls within the passed quarter
 */
export const isWithinQuarter = ([quarterStartDate, quarterEndDate]: [string, string], startDate: Date, endDate?: Date) => {
    /** validate if the start date falls between the quarter start and end dates if end date is not provided
     * provided start date should be greater than the quarter start date
     * prodived end date OR fallback to startDate should be sooner than the quarter end date
     */
    return startDate >= new Date(quarterStartDate) && (endDate ?? startDate) <= new Date(quarterEndDate);
};

/**
 * getCurrentQuarterIndex - returns the index for the current quarter
 * @returns {number} index of the current quarter
 */
export const getCurrentQuarterIndex = () => {
    return QUARTER_DATES.findIndex((quarterDates: string[]) => {
        const [startDate, endDate] = quarterDates;
        const today = new Date();

        /** today falls perfectly between the start and end date of the defined quarters */
        const isCurrentQuarter = isWithinQuarter(quarterDates as [string, string], today);

        /**
         * There is usually a difference of a day between when the quarter ends and the next quarter.
         * check if it is within 2 days of the end of quarter to treat that as the current quarter
         */
        if (!isCurrentQuarter) {
            const timeDiff = today.getTime() - new Date(endDate).getTime();
            const dateDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
            return today >= new Date(startDate) && dateDiff < 2;
        }
        return isCurrentQuarter;
    });
};

export const getCurrentQuarter = () => {
    const quarterIndex = getCurrentQuarterIndex();
    return quarterIndex >= 0 ? QUARTER_DATES[quarterIndex] : [];
};

export const generatePageHeaderMetaData = (courseType?: string, durationInSeconds?: number, languageCode?: string): MetadataItem[] => {
    return [
        {
            type: 'text',
            text: courseType ?? '',
            ariaLabel: 'Course Type',
        },
        {
            type: 'text',
            text: `${convertSecondsToHoursMinutes(durationInSeconds ?? 0)}`,
            ariaLabel: 'Duration',
        },
        {
            type: 'text',
            text: getDisplayNameFromLanguageCode(languageCode),
            ariaLabel: 'Language',
        },
    ];
};

export const generateStringArgs = (
    actionAreaStatus: enums.States | typeof DIGITAL_COURSE_NOT_ENROLLED | typeof INCOMPLETE_PREVIOUS_MODULES,
    digitalTraining?: { courseTitle?: string; courseDetails: string[]; nextModuleName?: string; numberOfCompletedCourses: number },
    aciCompletionDate?: Date,
) => {
    if (actionAreaStatus === enums.States.IN_PROGRESS && digitalTraining) {
        return {
            completedTrainings: digitalTraining.numberOfCompletedCourses.toString(),
            totalTrainings: digitalTraining.courseDetails.length.toString(),
        };
    } else if (actionAreaStatus === DIGITAL_COURSE_NOT_ENROLLED) {
        return {
            courseName: digitalTraining?.nextModuleName || digitalTraining?.courseTitle || '',
        };
    } else if (actionAreaStatus === enums.States.TROPHY && aciCompletionDate) {
        return {
            completionDate: aciCompletionDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }),
        };
    } else if (actionAreaStatus === enums.States.ENROLLED && digitalTraining) {
        return {
            courseName: digitalTraining?.nextModuleName || digitalTraining?.courseTitle || '',
        };
    }
};

export const getDisplayNameFromLanguageCode = (languageCode?: string) => {
    return (Locales.asList().find((locale) => locale.code === languageCode) ?? Locales.ENGLISH).displayName;
};

export const courseIsActiveForLearnerInCurrentQuarter = (courseDetails: CourseDetails | null) => {
    if (!courseDetails) {
        return false;
    }
    const courseIsWithdrawn = courseDetails?.aciRegistrationStatus && courseDetails.aciRegistrationStatus === RegistrationStatus.WITHDRAWN;
    const { start_timestamp, end_timestamp } = courseDetails.learningActivityGroup;
    const today = new Date();
    const startDate = new Date(start_timestamp * MILLISECONDS).toISOString();
    const endDate = new Date(end_timestamp * MILLISECONDS).toISOString();
    const courseIsActive = isWithinQuarter([startDate, endDate], today);
    return courseIsWithdrawn || !courseIsActive;
};
