import { gql, useLazyQuery } from '@apollo/client';
import { useNotifications } from '../notifications/useNotifications';

export const LEARNING_ACTIVITY_GROUP = gql`
    query learningActivityGroup($id: ID!) {
        learningActivityGroup(pk: $id) {
            pk
            start_timestamp
            end_timestamp
            catalog_item {
                duration {
                    unit
                    value
                }
                id
                languageCode
                name
                auditMetadata {
                    activatedAt
                }
                childrenCount
                semanticVersion {
                    major
                    minor
                    patch
                }
                status
            }
            learning_activities {
                pk
                delivery_sessions {
                    start_timestamp
                    end_timestamp
                    instructors {
                        name
                    }
                }
            }
        }
    }
`;

export const useLearningActivityGroup = (id: string) => {
    const { showErrorMessage } = useNotifications();
    return useLazyQuery(LEARNING_ACTIVITY_GROUP, {
        variables: {
            id: id,
        },
        onError: (error) => {
            showErrorMessage(error.message, `lag_${id}_error`);
        },
    });
};
