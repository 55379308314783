import { gql, useQuery } from '@apollo/client';
import { useNotifications } from '../notifications/useNotifications';
import { useUserInfo } from '../user';

export const ACCESS_BY_USER_ID_AND_CATALOG_ITEM_ID = gql`
    query accessByUserIdAndCatalogItemId($userId: ID!, $catalogItemId: ID!) {
        accessByUserIdAndCatalogItemId(userId: $userId, catalogItemId: $catalogItemId) {
            accessType
        }
    }
`;

export const useAccessByUserIdAndCatalogItemId = (catalogItemId: string) => {
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    return useQuery(ACCESS_BY_USER_ID_AND_CATALOG_ITEM_ID, {
        variables: { userId: user.id, catalogItemId: catalogItemId },
        skip: !user.id,
        onError: (error) => {
            showErrorMessage(error.message, `${catalogItemId}_access_error`);
        },
    });
};
