import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { useCatalogItem } from '../hooks/catalog/useCatalogItem';
import useGetLazyTranscriptIdAndStatus from '../hooks/transcript/useGetLazyTranscriptIdAndStatus';
import { CatalogItem } from '../common';

export interface CatalogItemAndChildrenTranscriptsContextType {
    item: CatalogItem | null;
    error: ApolloError | undefined;
    childTranscripts: any[];
    loading: boolean;
}

export const CatalogItemAndChildrenTranscriptsContext = createContext<CatalogItemAndChildrenTranscriptsContextType>({
    item: null,
    error: undefined,
    childTranscripts: [],
    loading: true,
});

interface CatalogItemAndChildrenTranscriptsProviderByItemIdProps {
    id: string | undefined | null;
    skipGetChildrenTranscript?: boolean;
    children: ReactNode;
}

export const CatalogItemAndChildrenTranscriptsProviderByItemId = ({
    id,
    children,
}: CatalogItemAndChildrenTranscriptsProviderByItemIdProps) => {
    const { data, error: catalogError, loading: catalogLoading } = useCatalogItem(id);
    const [childTranscripts, setChildTranscripts] = useState([]);
    const [transcriptError, setTranscriptError] = useState<ApolloError | undefined>(undefined);
    const [transcriptLoading, setTranscriptLoading] = useState(true);

    const { fetchTranscript } = useGetLazyTranscriptIdAndStatus();

    useEffect(() => {
        const fetchChildTranscripts = async () => {
            if (data?.catalogItemById?.children?.nodes) {
                try {
                    const transcriptPromises = data.catalogItemById.children.nodes.map(async (child: { id: any }) => {
                        if (child?.id) {
                            const result = await fetchTranscript(child.id);
                            return result || null;
                        }
                        return null;
                    });

                    const transcripts = await Promise.all(transcriptPromises);
                    // Do not filter nulls since it indicates if a child is enrolled or not
                    setChildTranscripts(transcripts as any);
                    setTranscriptLoading(false);
                } catch (error) {
                    setTranscriptError(
                        error instanceof ApolloError
                            ? error
                            : new ApolloError({
                                  errorMessage: 'An error occurred while fetching transcripts',
                              }),
                    );
                }
            }
        };

        fetchChildTranscripts();
    }, [data?.catalogItemById?.children?.nodes, fetchTranscript]);

    const error = catalogError || transcriptError;

    return (
        <CatalogItemAndChildrenTranscriptsContext.Provider
            value={{
                item: data?.catalogItemById || null,
                error,
                childTranscripts,
                loading: catalogLoading || transcriptLoading,
            }}
        >
            {children}
        </CatalogItemAndChildrenTranscriptsContext.Provider>
    );
};

export const useCatalogItemAndChildrenTranscripts = () => {
    const context = useContext(CatalogItemAndChildrenTranscriptsContext);
    if (context === undefined) {
        throw new Error('useCatalogItemAndTranscriptChildren must be used within a CatalogItemAndTranscriptChildrenProviderByItemId');
    }
    return context;
};
