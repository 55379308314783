import { ExperienceBridgeProvider } from '@amzn/aws-tc-xb-root-utils';
import { Locales } from '@amzn/xb-hooks';
import { AmazonCloudInstituteLayout } from '@amzn/sbux-lib';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import {
    ALLOWED_USER_GROUPS,
    CAREER_SERVICES_ROUTE,
    COURSE_DETAIL_ROUTE,
    DEFAULT_ROUTE,
    FAQ_ROUTE,
    FEATURE_FLAG,
    HOME_ROUTE,
    LEARNER_HANDBOOK_ROUTE,
    MATCH_ALL_ROUTE,
    QUICK_START_ROUTE,
    TRAINING_MODULE_DETAIL_ROUTE,
    getSkillBuilderLearnerDashboard,
    getXBEnvironment,
} from './common';
import { Flashbar, Footer, PageHeader } from './components';
import { config } from './config/XBProviderConfig';
import { useUserInfo } from './hooks';
import { CareerServices, Course, Dashboard, FAQ, LearnerHandbook, LoadingPage, PageNotFound, QuickStart, TrainingModule } from './pages';
import { TranscriptProvider } from './context/TranscriptContext';

const AppLayout = (props: any) => {
    const { user, loading } = useUserInfo();
    if (loading || !user?.id) return <LoadingPage />;
    else if (
        FEATURE_FLAG.PADAWAN_USER_GROUP_ACCESS_CONTROL &&
        user?.groups &&
        !ALLOWED_USER_GROUPS.some((group) => user.groups?.map((group) => group.toUpperCase()).includes(group.toUpperCase()))
    ) {
        const skillBuilderLearnerDashboardUrl = getSkillBuilderLearnerDashboard();
        window.location.href = skillBuilderLearnerDashboardUrl;
        return null;
    }

    return <AmazonCloudInstituteLayout header={<PageHeader />} content={props.children} footer={<Footer />} flashbar={<Flashbar />} />;
};

const App = () => {
    const XPProviderProps = {
        locale: Locales.ENGLISH,
        environment: getXBEnvironment(),
        config,
    };
    return (
        <BrowserRouter>
            <ExperienceBridgeProvider {...XPProviderProps}>
                <AppLayout>
                    <TranscriptProvider>
                        <Routes>
                            <Route path={DEFAULT_ROUTE} element={<Dashboard />} />
                            <Route path={HOME_ROUTE} element={<Dashboard />} />
                            <Route path={`${COURSE_DETAIL_ROUTE}/:id`} element={<Course />} />
                            <Route path={`${TRAINING_MODULE_DETAIL_ROUTE}/:id`} element={<TrainingModule />} />
                            <Route path={LEARNER_HANDBOOK_ROUTE} element={<LearnerHandbook />} />
                            <Route path={QUICK_START_ROUTE} element={<QuickStart />} />
                            <Route path={CAREER_SERVICES_ROUTE} element={<CareerServices />} />
                            <Route path={FAQ_ROUTE} element={<FAQ />} />
                            <Route path={MATCH_ALL_ROUTE} element={<PageNotFound />} />
                        </Routes>
                    </TranscriptProvider>
                </AppLayout>
            </ExperienceBridgeProvider>
        </BrowserRouter>
    );
};

export default App;
