import React from 'react';
import styled from 'styled-components';
import { Container, SpaceBetween } from '@amzn/awsui-components-react';
import AppStrings from '../../resources/strings';

const CustomContainer = styled(Container)`
    border: none !important;
    color: #5f6b7a !important;
`;

export const EmptyClassRecordings = () => {
    const strings = AppStrings.coursePage.emptyClassRecordings;
    const imageSource = '/public/dog_detective.svg';

    return (
        <CustomContainer data-testid='class-recording-empty-state'>
            <SpaceBetween alignItems='center' size='xs'>
                <img {...(strings?.imageAltText && { alt: strings.imageAltText })} src={imageSource} width='350' />
                <p>
                    <strong>{strings.headerTitle}</strong>
                </p>
                <p>{strings.description}</p>
            </SpaceBetween>
        </CustomContainer>
    );
};
