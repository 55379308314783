import { useLogger, useStickyNotifications } from '@amzn/xb-hooks';
import { useLocation } from 'react-router-dom';

export const useNotifications = () => {
    const { setMessages } = useStickyNotifications();
    const { log } = useLogger();
    const location = useLocation();

    const showSuccessMessage = (message: string, messageId?: string) => {
        if (!message) return;
        log.info(message);
        setMessages([
            {
                type: 'success',
                content: message,
                dismissLabel: 'Dismiss message',
                messageId,
            },
        ]);
    };

    const showErrorMessage = (message: string, messageId?: string) => {
        if (!message) return;
        log.error(`Error on page ${location.pathname}: ${message}`);
        setMessages([
            {
                type: 'error',
                content: message,
                dismissLabel: 'Dismiss message',
                messageId,
            },
        ]);
    };

    return { showSuccessMessage, showErrorMessage };
};
