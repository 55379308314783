import { gql, useMutation } from '@apollo/client';
import { useUserInfo } from '../user';
import { getErrorMessageFromErrorCode } from '@amzn/aws-tc-ilt-common';
import { useNotifications } from '../notifications/useNotifications';

export const REGISTER_VERSIONED_CATALOG_ITEM = gql`
    mutation registerVersionedCatalogItem($userId: ID!, $catalogItemVersionedId: ID!) {
        registerVersionedCatalogItem(userId: $userId, catalogItemVersionedId: $catalogItemVersionedId) {
            registrationStatus
            id
        }
    }
`;

export const useRegisterVersionedCatalogItem = (versionedCatalogItemId: string) => {
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    return useMutation(REGISTER_VERSIONED_CATALOG_ITEM, {
        variables: { userId: user.id, catalogItemVersionedId: versionedCatalogItemId },
        onError: (error) => {
            showErrorMessage(getErrorMessageFromErrorCode(error.message), `${versionedCatalogItemId}_registeration`);
        },
    });
};
