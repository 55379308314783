import { Skeleton } from '@amzn/sbux-lib';
import React, { useEffect, useState } from 'react';
import { useCourseData } from '../../context/CourseDetailContext';
import { useUserInfo } from '../../hooks';
import { getClassItem, getPreviousMonday } from '../calendar';
import { ClassSelection } from './ClassSelection';
import { RegisteredSchedule } from './RegisteredSchedule';
import { RegistrationStatus } from '../../common';

export const CourseClasses = () => {
    const { user } = useUserInfo();
    const [week, setWeek] = useState<Date>(getPreviousMonday(new Date()));
    const lag = useCourseData()?.learningActivityGroup;
    const registration = useCourseData()?.registration;
    const selectedClassId = registration?.learningActivity?.pk;
    const classItems = lag?.learning_activities
        .map((learningActivity) => getClassItem(learningActivity, week, user.timezone!))
        .sort((classItem1, classItem2) => (classItem1.schedule[0]?.recurrenceTime < classItem2.schedule[0]?.recurrenceTime ? -1 : 1));
    const classItem = classItems?.find((item) => item.id === selectedClassId);
    const isUserOnBreak = user?.groups &&
        user.groups?.some((group) => group.toUpperCase()=== 'PADAWAN PAUSED');
    const isCourseRegistered = registration?.registrationStatus === RegistrationStatus.REGISTERED;
    const buttonsHidden = !isCourseRegistered || isUserOnBreak;
    const [showClassSelection, setShowClassSelection] = useState(false);
    useEffect(() => {
        setShowClassSelection(!selectedClassId);
    }, [selectedClassId]);

    return !classItems ? (
        <Skeleton isLoading height={600} type='image' width='60%' borderRadius={20} />
    ) : (
        <div data-testid='course-classes-tab-content' style={{ width: '66%' }}>
            {showClassSelection ? (
                <ClassSelection
                    classItems={classItems!}
                    preselectedClassId={selectedClassId}
                    setShowClassSelection={setShowClassSelection}
                    buttonsHidden={buttonsHidden}
                />
            ) : (
                <RegisteredSchedule classItem={classItem} setShowClassSelection={setShowClassSelection} week={week} setWeek={setWeek} />
            )}
        </div>
    );
};
