import { AciTranscript, CatalogItemNode, DigitalTranscriptStatus, RegistrationStatus } from '../types';

/** determine if the user is registered to the LAG */
export const isRegisteredLAG = (transcripts: AciTranscript[], lagId: string) => {
    const courseLagTranscript = transcripts.find((transcript: AciTranscript) => transcript.learningActivityGroup?.pk === lagId);

    const lagRegistrationStatus = courseLagTranscript?.registration?.registrationStatus;
    return lagRegistrationStatus === RegistrationStatus.REGISTERED;
};

export const checkCompletedAllPreviousCourses = (
    coursesList: CatalogItemNode[] | undefined,
    id: string | undefined,
    coursesTranscripts: any[],
): boolean => {
    if (!coursesList || !id) return true;
    const currentCourseIndex = coursesList?.findIndex((course) => course.id === id);
    if (currentCourseIndex === 0) {
        return true;
    }

    return coursesTranscripts
        .slice(0, currentCourseIndex)
        .every((transcript) => transcript?.digitalTranscriptStatus === DigitalTranscriptStatus.Completed);
};
