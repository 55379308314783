import { Box, ColumnLayout, ContentLayout, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Skeleton } from '@amzn/sbux-lib';
import React from 'react';
import {
    APPLICATION_NAME,
    getManagePaymentUrl,
    GRADUATION_USER_GROUPS,
    isAciEnrollmentOpenNow,
    productCardFromTranscript,
} from '../../common';

import {
    Calendar,
    getCurrentQuarterCourses,
    getDashboardState,
    getNextQuarterCourses,
    NotRegistered,
    Graduated,
    ContinueJourney,
    getCompletedCourses,
} from '../../components';
import { SkillBuilderAnimatedGradientBackground } from "@amzn/sbux-lib";

import { useTranscriptData } from '../../context/TranscriptContext';
import { useUserInfo } from '../../hooks';
import AppStrings from '../../resources/strings';

const DashboardContent = () => {
    document.title = APPLICATION_NAME;
    const { user } = useUserInfo();
    const graduated = user.groups?.some((group) => GRADUATION_USER_GROUPS.includes(group));
    const { transcripts, loading } = useTranscriptData();
    const { dashboardPage } = AppStrings;

    const dashboardState = getDashboardState(transcripts);

    const YourCoursesSection = () => {
        const currentQuarterCourses = getCurrentQuarterCourses(transcripts);
        return (
            <SpaceBetween size='l' data-testid='your-courses-section'>
                <Header variant='h2' data-testid='your-courses'>
                    {dashboardPage.yourCourses}
                    {currentQuarterCourses.length ? ` (${currentQuarterCourses.length})` : ''}
                </Header>
                <ColumnLayout columns={3}>{currentQuarterCourses.map(productCardFromTranscript)}</ColumnLayout>
            </SpaceBetween>
        );
    };

    const NextQuarterCoursesSection = () => {
        const nextQuarterCourses = getNextQuarterCourses(transcripts);
        return (
            <SpaceBetween size='l' data-testid='next-quarter-courses-section'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    data-testid='next-quarter'
                >
                    <div>
                        <Header variant='h2'>{`${dashboardPage.nextQuarter} (${nextQuarterCourses.length})`}</Header>
                    </div>
                </div>
                {nextQuarterCourses.length === 0 ? (
                    <NotRegistered />
                ) : (
                    <ColumnLayout columns={3}>{nextQuarterCourses.map(productCardFromTranscript)}</ColumnLayout>
                )}
            </SpaceBetween>
        );
    };

    const CompletedCoursesSection = () => {
        const completedCourses = getCompletedCourses(transcripts);
        return (
            <SpaceBetween size='l' data-testid='completed-courses-section'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    data-testid='completed-courses'
                >
                    <div>
                        <Header variant='h2'>{`${dashboardPage.completedCourses} (${completedCourses.length})`}</Header>
                    </div>
                </div>
                {completedCourses.length === 0 ? (
                    <NotRegistered />
                ) : (
                    <ColumnLayout columns={3}>{completedCourses.map(productCardFromTranscript)}</ColumnLayout>
                )}
            </SpaceBetween>
        );
    };

    const [bannerMessages, setBannerMessages] = React.useState([
        {
            type: 'info',
            dismissible: true,
            onDismiss: () => setBannerMessages([]),
            content: (
                <>
                    <strong>Your graduation ceremony recording is now available</strong>
                    <br />
                    Watch the graduation ceremony, and share the link with friends and family. Use password ACIGraduation25 to view.
                    <div
                        style={{
                            position: 'absolute',
                            top: '15%',
                            right: '4%',
                        }}
                    >
                        <Button
                            onClick={() =>
                                window.open(
                                    'https://awsvirtual.webex.com/recordingservice/sites/awsvirtual/recording/playback/624a375c051c4bdab82697e78f1d66d6',
                                    '_blank',
                                )
                            }
                        >
                            Watch Recording
                        </Button>
                    </div>
                </>
            ),
        } as FlashbarProps.MessageDefinition,
    ]);

    const renderDashboardSections = () => {
        if (loading) {
            return <Skeleton isLoading height={800} type='image' width='100%' borderRadius={20} />;
        }

        if (graduated) {
            return (
                <>
                    <Graduated />
                    <ContinueJourney />
                    <CompletedCoursesSection />
                </>
            );
        }
        if (dashboardState.notRegistered) {
            return <NotRegistered />;
        }
        return (
            <>
                <Calendar schedule={dashboardState.schedule ?? { events: [] }} />
                {dashboardState.isYourCoursesVisible && <YourCoursesSection />}
                {isAciEnrollmentOpenNow() && <NextQuarterCoursesSection />}
            </>
        );
    };
    return (
        <div id='dashboard-content'>
            <ContentLayout>
                {graduated ? (
                    <div data-testid='flashbar'>
                        <Flashbar items={bannerMessages} />
                    </div>
                ) : (
                    <div />
                )}
                <SpaceBetween size='l'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '40px',
                        }}
                    >
                        <div>
                            <Header variant='h1'>{graduated ? dashboardPage.congratulationsGraduate : dashboardPage.welcome}</Header>
                        </div>
                        {/**
                         * Need to display manage payment link if the user is registered for courses in current / future quarters
                         * dashboardState.notRegistered denotes that user is not registered to any course for which we render a different component
                         *
                         */}
                        <Box float='right'>
                            <SpaceBetween size='l' direction='horizontal'>
                                {graduated ? (
                                    <div style={{ position: 'relative', top: '80%' }}>{dashboardPage.graduateCAD}</div>
                                ) : (
                                    <Link href={getManagePaymentUrl()}>{dashboardPage.manageEnrollment}</Link>
                                )}
                            </SpaceBetween>
                        </Box>
                    </div>

                    {renderDashboardSections()}
                </SpaceBetween>
                <div style={{ height: '40px' }}></div>
            </ContentLayout>
        </div>
    );
};

export const Dashboard = () => {
    return <SkillBuilderAnimatedGradientBackground>{DashboardContent()}</SkillBuilderAnimatedGradientBackground>;
};
