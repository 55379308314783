import { useRef } from 'react';
import styled from 'styled-components';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { ColumnLayout, Container, Header, SpaceBetween, Button } from '@amzn/awsui-components-react';
import AppStrings from '../../resources/strings';
import React from 'react';
import { useCourseData } from '../../context/CourseDetailContext';
import { Skeleton } from '@amzn/sbux-lib';
import { courseIsActiveForLearnerInCurrentQuarter } from '../../common';

/** TODO -  Used styles to match the live component styles. Need to work on reusing the tokens exported from SBUX libraries. */
const OfficeHoursContainer = styled(ColumnLayout)`
    @media (min-width: 768px) {
        width: 65.5%;
    }
`;

export const CourseDetails = () => {
    const courseDetails = useCourseData();
    const course = courseDetails?.course;
    const { coursePage: pageStrings } = AppStrings;
    const xpsRef = useRef(null);

    const getOfficeHoursUrl = () => {
        if (course && course?.customAttributes) {
            if (course?.customAttributes?.length > 0) {
                const officeHoursAttribute = course.customAttributes.find((attribute) => attribute.key === 'prelude_url');
                return officeHoursAttribute?.values[0] || '';
            }
        }
        return '';
    };
    const officeHoursUrl = getOfficeHoursUrl();

    const shouldDisableOfficeHourRegistration = courseIsActiveForLearnerInCurrentQuarter(courseDetails);

    return course?.id ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <LiveComponent
                componentName='DetailsTab'
                experienceProviderName='productdetailpage'
                fallback={<div ref={xpsRef} />}
                props={{ ids: { catalogItemVariantId: course?.id }, flavor: 'aci' } as any}
            />
            <OfficeHoursContainer>
                {officeHoursUrl && (
                    <Container data-testid='office-hours-container'>
                        <Header
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button
                                        iconName='external'
                                        onClick={() => {
                                            window.open(officeHoursUrl, '_blank');
                                        }}
                                        disabled={shouldDisableOfficeHourRegistration}
                                        data-testid='office-hours-button'
                                    >
                                        {pageStrings.officeHoursButtonLabel}
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {pageStrings.officeHoursHeadingText}
                        </Header>
                        <p style={{ padding: '8px 0px' }}>{pageStrings.officeHoursDescription}</p>
                    </Container>
                )}
            </OfficeHoursContainer>
        </div>
    ) : (
        <Skeleton isLoading height={600} type='image' width='60%' borderRadius={20} />
    );
};
