import { gql, useQuery } from '@apollo/client';
import { useUserInfo } from '../user';
import { useNotifications } from '../notifications/useNotifications';

export const ACI_TRANSCRIPTS_BY_USER_ID = gql`
    query aciTranscriptsByUserId($userId: ID!) {
        aciTranscriptsByUserId(userId: $userId) {
            id
            learningActivityGroup {
                pk
                program_name
                catalog_item {
                    awsServices {
                        valuesDisplayStrings
                    }
                    domain {
                        valueDisplayString
                    }
                    description
                    duration {
                        unit
                        value
                    }
                    id
                    languageCode
                    name
                    skillLevel {
                        valueDisplayString
                    }
                    customAttributes {
                        key
                        values
                    }
                    children {
                        nodes {
                            id
                        }
                    }
                }
                learning_activities {
                    pk
                    delivery_sessions {
                        start_timestamp
                        end_timestamp
                        delivery_session_type
                        v_ilt_info {
                            type
                            url
                            recording_url
                        }
                        instructors {
                            name
                        }
                    }
                }
            }
            aciTranscriptStatus
            digitalTraining {
                numberOfCompletedCourses
                courseDetails
            }
            completionDate
            registration {
                registrationStatus
                learningActivity {
                    pk
                    delivery_sessions {
                        start_timestamp
                        end_timestamp
                        delivery_session_type
                        v_ilt_info {
                            type
                            url
                            recording_url
                        }
                        instructors {
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const useAciTranscriptsByUserId = () => {
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    return useQuery(ACI_TRANSCRIPTS_BY_USER_ID, {
        variables: {
            userId: user.id,
        },
        skip: !user.id,
        onError: (error) => {
            showErrorMessage(error.message, 'transcripts_error');
        },
    });
};
