import { Grid, IconProps } from '@amzn/awsui-components-react';
import { enums, ActionArea, CourseHeaderV2 as CourseHeader } from '@amzn/sbux-lib';
import React from 'react';
import { generatePageHeaderMetaData, generateStringArgs, getEnrollmentSelectionUrl, getString, RegistrationStatus } from '../../common';
import { useCourseData } from '../../context/CourseDetailContext';
import AppStrings from '../../resources/strings';
import { isWithinTimeWindow } from '../../common/utils/dateUtils';

interface BasicActionArea {
    headerTitle: string;
    description: string;
    actionLabel: string;
    iconName?: IconProps.Name;
}

interface InProgressActionArea {
    active: BasicActionArea;
    default: BasicActionArea;
}

type ActionAreaStrings = BasicActionArea | InProgressActionArea;

export const CoursePageHeader = () => {
    const coursePage = AppStrings.coursePage;
    const courseDetails = useCourseData()?.course;
    const actionAreaStatus = useCourseData()?.actionAreaStatus;
    const joinSessionInfo = useCourseData()?.joinSessionInfo;
    const digitalTraining = useCourseData()?.digitalTraining;
    const aciCompletionDate = useCourseData()?.aciCompletionDate;
    const courseRegistration = useCourseData()?.registration;
    const isLearnerRegistered = courseRegistration?.registrationStatus === RegistrationStatus.REGISTERED;

    const sessionStartTime = joinSessionInfo?.startTimeStamp ? new Date(joinSessionInfo.startTimeStamp).getTime() * 1000 : null;
    const sessionEndTime = joinSessionInfo?.endTimeStamp ? new Date(joinSessionInfo.endTimeStamp).getTime() * 1000 : null;

    const joinSessionClickHandler = () => {
        if (isLearnerRegistered && joinSessionInfo?.url) {
            window.open(joinSessionInfo.url, '_blank');
        }
    };

    const getActionArea = () => {
        if (actionAreaStatus === enums.States.IN_PROGRESS) {
            const inProgressStrings = coursePage.headerActionArea[actionAreaStatus] as InProgressActionArea;
            return isWithinTimeWindow(sessionStartTime, sessionEndTime) ? inProgressStrings.active : inProgressStrings.default;
        }
        return (actionAreaStatus ? (coursePage.headerActionArea[actionAreaStatus] as ActionAreaStrings) : null) as BasicActionArea;
    };

    const actionAreaStrings = getActionArea();

    return (
        <div
            data-testid='course-page-header'
            style={{
                display: 'flex',
                padding: '28px 0',
                justifyContent: 'center',
            }}
        >
            <div style={{ maxWidth: '1152px', width: '100%' }}>
                {courseDetails && actionAreaStatus && actionAreaStrings && (
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                        <CourseHeader
                            courseTitle={courseDetails.name}
                            progress='in_progress'
                            selectedLanguage={courseDetails.languageCode}
                            metadataItems={generatePageHeaderMetaData(
                                courseDetails?.type?.valueDisplayString!,
                                courseDetails?.duration.value,
                                courseDetails?.languageCode,
                            )}
                        />
                        <ActionArea
                            state={actionAreaStatus}
                            heading={actionAreaStrings.headerTitle}
                            description={getString(
                                actionAreaStrings.description,
                                generateStringArgs(actionAreaStatus, digitalTraining, aciCompletionDate),
                            )}
                            {...(!isLearnerRegistered ? { hideIllustration: true } : {})}
                            {...(actionAreaStrings?.actionLabel && {
                                primaryButton: {
                                    ariaLabel: actionAreaStrings.actionLabel,
                                    buttonLabel: actionAreaStrings.actionLabel,
                                    onClick: joinSessionClickHandler,
                                    variant: 'normal',
                                    iconName: actionAreaStrings?.iconName,
                                    ...(!isLearnerRegistered
                                        ? { target: '_blank', fullWidth: false, href: getEnrollmentSelectionUrl() }
                                        : {}),
                                },
                            })}
                        />
                    </Grid>
                )}
            </div>
        </div>
    );
};
