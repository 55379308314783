export interface CatalogItem {
    awsServices?: MultiValue;
    domain?: SingleValue;
    description: string;
    duration: {
        unit: string;
        value: number;
    };
    id: string;
    languageCode: string;
    name: string;
    customAttributes?: {
        key: string;
        values: string[];
    }[];
    children?: {
        nodes: CatalogItemNode[];
    };
    skillLevel?: SingleValue;
}

/** Track XB work to use the types from supergraph or make it break it down for reusability */
export interface CatalogItemNode {
    id: string;
}

interface SingleValue {
    valueDisplayString: string;
}

interface MultiValue {
    valuesDisplayStrings: string[];
}

export interface BroadcastItem {
    title: string;
    date: Date;
    recordingUrl: string;
    duration: number;
    description: string;
    watched: boolean;
}

export interface CourseClassItem {
    id: string;
    instructors: string[];
    schedule: {
        recurrenceDay: string;
        recurrenceTime: string;
    }[];
}

export interface AciTranscript {
    id: string;
    digitalTraining?: {
        numberOfCompletedCourses: number;
        courseDetails: any[];
    };
    completionDate?: string;
    learningActivityGroup: LearningActivityGroup;
    aciTranscriptStatus: AciCourseLoadTranscriptStatus;
    registration: Registration;
    timezone: string;
}

export interface Registration {
    registrationStatus: RegistrationStatus;
    learningActivity: LearningActivity;
}

export interface LearningActivityGroup {
    pk: string;
    learning_activities: LearningActivity[];
    catalog_item: CatalogItem;
    end_timestamp: number;
    start_timestamp: number;
    program_name: string;
}

export enum AciCourseLoadTranscriptStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
}

export interface LearningActivity {
    pk: string;
    activity_status: LearningActivityStatus;
    course_name: string;
    delivery_language: string;
    delivery_sessions: DeliverySession[];
    delivery_timezone: string;
}

export interface DeliverySession {
    start_timestamp: number;
    end_timestamp: number;
    delivery_session_type: string;
    v_ilt_info: VIltInfo;
    instructors: {
        name: string;
    }[];
}

export interface VIltInfo {
    type: VIltType;
    url: string;
    recording_url?: string;
}

export enum VIltType {
    PVILT,
    WebEx,
}

export enum LearningActivityStatus {
    ACTIVE = 'Active',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed',
}

export enum RegistrationStatus {
    REGISTERED = 'REGISTERED',
    WITHDRAWN = 'WITHDRAWN',
}

export interface EventSchedule {
    events: EventType[];
}

export interface EventType {
    title: string;
    date: Date;
    timezone: string;
    durationInMinutes: number;
    description: string;
    learningActivityGroupId: string;
    catalogItemName: string;
    url: string;
    color?: string;
}

export enum SkillLevel {
    Fundamental = 'Fundamental',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced',
}

export type IconSize = 'small' | 'large';

export type IconProps = {
    value?: string;
    children?: JSX.Element;
    size?: IconSize;
};

export interface DashboardState {
    schedule?: EventSchedule;
    notRegistered?: boolean;
    isYourCoursesVisible?: boolean;
}

export interface ClassSchedule {
    days: number[];
    start: string;
    duration: number;
}
