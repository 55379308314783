import { useAuth } from '@amzn/xb-hooks';
import { useEffect, useState } from 'react';
import { getTimezoneOrDefault, RoleType } from '../../common';
import { useGetCurrentUser } from './getCurrentUser';

export interface ApplicationUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: RoleType;
    groups?: string[];
    timezone?: string;
}

export const useUserInfo = () => {
    const [user, setUser] = useState<ApplicationUser>({});

    const { data, error, loading } = useGetCurrentUser();
    const { login } = useAuth();

    useEffect(() => {
        if (error) {
            login(window.location.href);
        }

        if (data) {
            setUser({
                id: data.currentUser.id,
                firstName: data.currentUser.firstName,
                lastName: data.currentUser.lastName,
                email: data.currentUser.emailAddress,
                role: data.currentUser.userRole,
                groups: data.currentUser.groups?.nodes.map((group: { name: string }) => group.name),
                timezone: getTimezoneOrDefault(data.currentUser.preferences?.timezone),
            });
        }
    }, [data, error]);

    return { user, loading };
};
